import React, {
  useRef, useEffect,
} from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { format } from 'date-fns';
import BoxPanel from '../../../BoxPanel/BoxPanel';
import {
  generateKey, getHourFromDate, isImage, isValidDate,
} from '../../../../utils/utils';

import styles from './Guest.module.scss';
import { API_URL } from '../../../../actions';
import FilesPreview from '../../../FilesPreview/FilesPreview';

export default function Guest({
  congress,
  activeForm,
}) {
  const containerRef = useRef();
  const hotels = congress?.accommodation?.hotels;
  const meals = congress?.accommodation?.meals;

  useEffect(() => {
    if (containerRef?.current.scrollTop) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [activeForm]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <BoxPanel label={'INVITés'}>
            <section>
              {congress.guestsFile?._id
                && <>
                    <label>Invités</label>
                    {congress.guestsFile
                      && <FilesPreview key="guestsFile" files={[congress.guestsFile]} />
                    }
                </>
              }
              {congress.guestsRoomingList?._id
                && <>
                    <label>Rooming list</label>
                    {congress.guestsRoomingList
                      && <FilesPreview key="guestsRoomingList" files={[congress.guestsRoomingList]} />
                    }
                </>
              }
              {congress?.atrium
                && <>
                  <label>Liens vers atrium</label>
                  <div className={styles.atrium}>
                    <a href={congress.atrium}
                    className={styles.link} target="_blank" rel="noreferrer" >{congress.atrium}</a>
                  </div>
                </>
              }
            </section>
          </BoxPanel>
          <BoxPanel label={'HOTEL'}>
            <div className={styles.list}>
              {hotels?.map((h, i) => (
                <div key={generateKey('hotel', i)} className={styles.card}>
                  <div className={styles.content}>
                    <p><b>{h.name}</b></p>
                    <div className={styles.address}>
                      <label>{h.address}</label>
                    </div>
                    <div className={styles.hour}>
                      <label><AiOutlineClockCircle />Horaire checkin</label> <b>{h.checkin}</b>
                    </div>
                    <div className={styles.hour}>
                      <label><AiOutlineClockCircle />Horaire checkout</label> <b>{h.checkout}</b>
                    </div>
                  </div>
                  {isImage(h?.plan?.name)
                    && <div className={styles.plan}>
                        <a href={[API_URL, '/', h?.plan?.path].join('')} target='_blank' rel="noreferrer">
                        <img src={[API_URL, '/', h?.plan?.path].join('')} alt={h.plan?.name} />
                      </a>
                    </div>
                  }
                </div>
              ))}
            </div>
          </BoxPanel>
          <BoxPanel label={'REPAS'}>
            <div className={styles.meals}>
              {meals?.map((m, i) => (
                <div
                  key={generateKey('repas', i)}
                  className={styles.item}
                >
                  <p>{m.type}</p>
                  <p>{isValidDate(m.date) && format(new Date(m.date), 'dd/MM/yyyy')} {getHourFromDate(m.date)}</p>
                  <p>{m.name}</p>
                </div>
              ))}
            </div>
          </BoxPanel>
        </div>
      </div>
    </div>
  );
}
